<template>
<div class="modal-backdrop" @click.self="$emit('close')">
<form class="modal" @submit.prevent="sendTicket" >
  <div class="d-flex justify-space-between">
    <h3>ارسال تیکت</h3>
    <img  @click.self="$emit('close')" src="../../../assets/Icons/close.svg" alt="">
  </div>
  <input class="input-container" type="text" placeholder="عنوان" v-model="subject" @input="value=>type=value">
  <select name="type" id="" v-model="type">
    <option value="" disabled selected hidden>نوع تیکت</option>
    <option v-for="(item,index) in typeValues" :value="item.value" :key="index">{{
        item.text
      }}</option>

  </select>
  
<textarea placeholder=" پیام شما" v-model="text"/>
  <button :disabled="disable" class="btn-primary" >{{
    disable ? disable : 'ارسال'
    }}</button>
</form>
</div>
</template>

<script>


export default {

  name: "NewTicket",

  data() {
    return {
typeValues:[
  {
    value:"GENERAL",
    text:"عمومی"
  },
  {
    value:"TECHNICAL",
    text:"فنی"
  }
],
      text: "",
      subject:'',
      type:'GENERAL'
    };
  },
  computed:{
    disable(){
      let Errors;
 if(!this.subject){
    Errors = 'عنوان تیکت را وارد کنید'
 }
 else if(!this.type){
   Errors = 'نوع تیکت را وارد کنید'
 }
 else if(!this.text){
    Errors = 'متن تیکت را وارد کنید'
  }




      return Errors;
    }
  },
  methods:{
    async sendTicket(){
      const form = new FormData();
      form.append('type',this.type);
      form.append('subject',this.subject);
      form.append('text',this.text);
      const res = await this.$axios.post('/tickets',form);
      if(res.message.includes('Created')){
this.$error('تیکت شما با موفقیت ارسال شد','','success');
this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container{
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
  height: 48px;
  padding: 10px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.modal{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  min-height: 350px;
  justify-content: space-between;
  padding: 24px;
button{
  margin-top: 1em;
  align-self: center;
  width: 100%;

  height: 3rem;
}
  textarea{
min-height: 200px;
    background: #F0F0F0;
    border-radius: 8px 8px 0px 0px;
    max-width: 100%;
    min-width: 100%;
    padding: 10px;
    max-height: 200px;
  }

}
select{
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
  height: 3.5em;
  padding: .5em;

}
</style>