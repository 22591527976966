<template>
  <div class="tickets">
    <div class="table-container">
      <table>
        <tr class="tr-head gray small">
          <td>نوع تیکت</td>
          <td>زمان و تاریخ</td>
          <td>وضعیت</td>
        </tr>
        <tr class="tr-body hover" v-for="(ticket,index) in ticketsHistory" :key="index">
          <td class="small">{{ ticketType[ticket.type] }}</td>
          <td class="small">{{ $G2J(ticket.createdAtDateTime) }}</td>
          <td class="small" :class="{'green': ticket.status ==='APPROVED','yellow':ticket.status ==='PENDING'}">
            {{ $status[ticket.status] }}
          </td>
          

        </tr>
      </table>
    </div>
    <div class="chat-box">
      <p class="secondary large title">احراز هویت</p>
      <div class="chat-container">
<div class="chat" v-for="(chat,index) in ticketsHistory" :key="index" :class="{'chat-customer':chat.customer===state.userInfo.email,'chat-support':chat.customer!==state.userInfo.email} ">
  <p class="regular">{{chat.text}}</p>

  <div class="info">
    <span class="small gray">{{chat.customer}}</span>
    <span class="small gray">{{$G2J(chat.createdAtDateTime)}}</span>
  </div>

</div>
      </div>
      <div class="send-ticket">
        <input type="text" placeholder="پیام به پشتیبانی">
        <button class="btn-primary"><svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.99997 1L1.70708 4.29289C1.31655 4.68342 1.31655 5.31658 1.70708 5.70711L4.99997 9M1.99997 5.00001L16 5.00001" stroke="#4B4949" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tickets",
  data() {
    return {
      ticketType:{
        GENERAL:'عمومی',
        TECHNICAL:'فنی',


      },
      ticketsHistory: [],
    }
  },
  methods:{
    async getTicketsHistory(){
      let res =await this.$axios('/tickets')
      res=res.content;
      res = res.sort((a,b)=>{
        return new Date(a.createdAtDateTime) - new Date(b.createdAtDateTime)
      })
      this.ticketsHistory=res;

    },
  },
  mounted() {
    this.getTicketsHistory();
  }
}
</script>

<style lang="scss" scoped>


.chat-customer{
  background: #F5F2E9;
  border-radius: 12px 0px 12px 12px;
  align-self:flex-end !important;
}
.chat-support{
  background: #FDFDFD;
  border-radius: 0px 12px 12px 12px;


  align-self: flex-start !important;
}











.tickets {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  flex-direction: row !important;
.title{
  align-self: center;
}
  .table-container {
    //width: 100%;
    //max-width: 545px;
    flex: 1 1 auto;
    background: #F8F8F8;
    /* p2p cards */
    max-height: 520px;
    overflow: auto;
    box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
    border-radius: 12px;
    padding: 2rem;

    table {


      td{
        padding: 1rem;
      }

td:nth-child(2) {
  text-align: center;
}


      width: 100%;
      border-collapse: collapse;

      .tr-head {
        position: sticky;
        background: #F8F8F8;
        top: 0;
      }


      td {
        height: 56px;
        border-bottom: 1px solid var(--gray-lighter);
      }

      td:last-child {
        text-align: left;
      }
    }
  }

  .chat-box {
    gap: 3em;
    flex: 2 2 auto;
    background: #F8F8F8;
    /* p2p cards */
    max-height: 520px;
    //overflow: auto;
    box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
    border-radius: 12px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    //align-items: center;
  .chat-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    max-height: 80%;
    overflow: auto;

  }
  }
}

.chat{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  padding: 1rem clamp(12px,2vw,24px);
  gap: 24px;
  .info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }
}

.send-ticket{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  button{
    height: 100%;
  }
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
}

@media(max-width: 500px){
  .chat-box{
    max-width: 99%;
  }
}
</style>