<template>
  <div class="tickets">
    <NewTicket v-if="newTicket" @close="newTicket=false"/>
    <div class="tickets-header d-flex">
      <p class="large">با ارسال تیکت به راحتی با پشتیبان های حرفه ای ما در ارتباط باشید. در اسرع وقت به درخواست های شما
        پاسخ داده می شود.</p>
      <button class="btn-primary" @click="newTicket = !newTicket">ارسال تیکت جدید</button>
    </div>
    <div class="tickets">
      <Tickets/>
    </div>
    <div class="faq">

      <FAQ :data="messages"/>
    </div>

    

  </div>
</template>

<script>
import showInfoDropDown from "@/components/PublicComponents/ShowInfoDropDown";
import Tickets from "./Tickets";
import NewTicket from "@/components/Panel/Tickets/NewTicket";

export default {
  name: "Main",
  components: {
    NewTicket, 'FAQ': showInfoDropDown,Tickets
  },
  data() {
    return {
      messages: [],
      newTicket:false,
    }
  },
  methods: {
    async getFaq() {
      this.loading = true
      this.messages = await this.$axios.get('/faq')
      this.loading = false

    }
  },
  mounted() {
    this.getFaq();
  },

}
</script>

<style lang="scss" scoped>
.tickets {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .tickets-header {
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    min-height: 100px;
    background: #F8F8F8;
    /* p2p cards */
padding: 24px 2rem;
    box-shadow: 0 0 2px rgba(140, 150, 153, 0.34);
    border-radius: 12px;
    button{
      height:44px;
      width:100%;
      max-width: 194px;
    }


  }

  .faq {
    max-height: 300px;
    overflow-y: scroll;
  }
}
</style>